import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function Footer() {
    return (
        <>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <span>Adresse:</span><br />
                            <span>Centre National de l'Information Géo-Spatiale (CNIGS) 33, Rue Armand Holly, Debussy, Turgeau, Port-au-Prince, Haïti</span>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <span>Telephones:</span><br />
                            <span>+509 2813-0412, +509 2813-0413, +509 2813 0596</span><br />
                            <span>Email: info@cnigs.ht; direction.generale@cnigs.ht</span>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Footer;