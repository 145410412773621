import React, { useState } from 'react';
import './App.css';
import Footer from './Footer';

function Partner() {
  const [filter, setFilter] = useState('');

  let partner_institutions = [
    {
      title: 'CNIGS',
      subtitle: 'Centre National de l\'Information Géo-Spatiale',
      img: 'cnigs-logo.png',
      imgstyle: { height: '50%' },
      link: 'http://www.cnigs.ht'
    },
    {
      title: 'DGPC',
      img: 'protectioncivile.PNG',
      imgstyle: {},
      link: 'https://protectioncivile.gouv.ht/'
    },
    {
      title: 'MPCE',
      img: 'mpce.PNG',
      imgstyle: {},
      link: 'https://www.facebook.com/MPCEHa/'
    },
    {
      title: 'MICT',
      subtitle: 'Ministère de l\'Intérieur et des Collectivités Territoriales',
      img: 'mict.PNG',
      imgstyle: {},
      link: 'https://www.mict.gouv.ht/'
    },
    {
      title: 'WB Haiti',
      img: 'logo-wb-header-en.svg',
      imgstyle: {},
      link: 'https://www.worldbank.org/en/country/haiti'
    },
    {
      title: 'BID Haiti',
      img: 'BID.PNG',
      imgstyle: { height: '50%' },
      link: 'https://www.iadb.org'
    },
    {
      title: 'EU Haiti',
      img: 'ue-union-europea.svg',
      imgstyle: {},
      link: 'https://www.eeas.europa.eu/delegations/haiti_fr'
    },
    {
      title: 'PNUD Haiti',
      img: 'pnud-logo-blue.svg',
      imgstyle: { height: '277px', width: '136px' },
      link: 'https://www.undp.org/fr/haiti'
    },
  ];

  return (
    <>

      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <img src="cnigs-logo.png" className="card-img-top" alt="..." style={{ height: '70px', width: '70px' }} />
          <a className="navbar-brand" href="#">HaitiData</a>
          <span />
        </div>
      </nav>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Accueil</a></li>
          <li class="breadcrumb-item active" aria-current="page">Institutions partenaires</li>
        </ol>
      </nav>

      <div className="container"> {/* text-center */}

        <div className="row">
          <span>Cette section vous propose des liens vers les sites des institutions partenaires, essentielles pour le développement et la gestion des projets géospatiaux en Haïti. Ces organismes collaborent pour améliorer la diffusion et l'utilisation des données géographiques au niveau national. Découvrez leurs missions et contributions en visitant leurs sites</span>
        </div>

        <div className="row">
          {
            partner_institutions
              .filter(
                item => item?.title?.toUpperCase()?.includes(filter?.toUpperCase())
                  || item?.subtitle?.toUpperCase()?.includes(filter?.toUpperCase())
                  || item?.text?.toUpperCase()?.includes(filter?.toUpperCase())
              )
              .map((item, index) =>
                <div key={index} className="col-lg-3 col-md-6 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">{item.title}</h5>
                      <h6 className="card-subtitle mb-2 text-body-secondary">{item.subtitle}</h6>
                      <p className="card-text">{item.text}</p>
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <img src={item.img} className="card-img-top" alt="..." style={item.imgstyle} />
                    </div>
                    <div class="card-footer d-flex align-items-center justify-content-center">
                      <a href={item.link} className="btn btn-primary" target="_blank">En savoir plus</a>
                    </div>
                  </div>
                </div>)
          }
        </div>
      </div>

      <Footer/>
    </>
  );
}

export default Partner;
