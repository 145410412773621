import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Footer from './Footer';

function App() {
  const [filter, setFilter] = useState('');

  let mycards = [
    {
      title: 'Geoportail',
      subtitle: '',
      text: "Le géoportail est une application web open source pour créer, gérer et partager des cartes et des données spatiales. Elle servira de vitrine pour le CNIGS, améliorant ainsi la visualisation et l'accessibilité des couches spatiales d'Haïti. Il s'agit d'un outil cartographique dynamique et convivial, conçu pour les utilisateurs non techniques de SIG, facilitant ainsi la création et l'interaction avec les données spatiales pour un public plus large. Cette application a été développée à partir de Mapstore.",
      img: 'MapStore2.png',
      link: '/mapstore/?locale=fr'
    },
    {
      title: 'Open-data',
      subtitle: '',
      text: "La plateforme de données ouvertes Open Data a été conçue pour la gestion, le partage et la visualisation de données géospatiales, principalement destinée aux utilisateurs expérimentés en SIG et cartographie. Le CNIGS a utilisé GeoNode pour développer cette plateforme, dans le but de diffuser des données spatiales en Haïti, améliorant ainsi l'accessibilité à l'information et soutenant une prise de décision éclairée.                                     ",
      img: 'geonode_logo.png',
      imgstyle: { height: '80px', width: '273px' },
      alert: "Notre plateforme d'open data est en cours d'amélioration et sera bientôt de nouveau en ligne. Nous nous excusons pour ce désagrément et vous remercions pour votre compréhension."
    }
  ];

  let mylinks = [
    {
      title: 'Autres sources de données spatiales',
      text: "Cette section permet d'accéder aux plateformes partenaires spécialisées en données géospatiales en Haïti, offrant des outils pour gérer, partager et visualiser des informations spatiales essentielles au développement du pays",
      to: '/others'
    },
    {
      title: 'Institutions partenaires',
      text: "Cette section vous propose des liens vers les sites des institutions partenaires, essentielles pour le développement et la gestion des projets géospatiaux en Haïti. Ces organismes collaborent pour améliorer la diffusion et l'utilisation des données géographiques au niveau national. Découvrez leurs missions et contributions en visitant leurs sites",
      to: '/partnerinstitutions'
    }
  ];


  /*
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <form className="d-flex" role="search">
            <input className="form-control me-2" type="search" placeholder="Recherche" aria-label="Search"
              value={filter}
              onChange={(event) => setFilter(event.target.value)}
            />
          </form>
        </div>
  */


  return (
    <>
      <ToastContainer />
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <a href="http://www.cnigs.ht" target="_blank"><img src="cnigs-logo.png" className="card-img-top" alt="..." style={{ height: '70px', width: '70px' }} /></a>
          <a className="navbar-brand" href="#">HaitiData</a>
          <span />
        </div>
      </nav>

      <div className="container"> {/* text-center */}

        <div className="row">
          <span>Bienvenue sur HaitiData.org, la plateforme officielle du <b>Centre National de l'Information Géo-Spatiale (CNIGS).</b> Ici, vous accédez librement à des données géo-spatiales sur Haïti, essentielles pour la planification territoriale, les évaluations des risques et les études socio-économiques. Explorez et utilisez ces informations pour contribuer au développement du pays.</span>
        </div>

        <div className="row">
          {
            mycards
              .filter(
                item => item?.title?.toUpperCase()?.includes(filter?.toUpperCase())
                  || item?.subtitle?.toUpperCase()?.includes(filter?.toUpperCase())
                  || item?.text?.toUpperCase()?.includes(filter?.toUpperCase())
              )
              .map((item, index) =>
                <div key={index} className="col-md-6 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">{item.title}</h5>
                      <h6 className="card-subtitle mb-2 text-body-secondary">{item.subtitle}</h6>
                      <p className="card-text">{item.text}</p>
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <img src={item.img} className="card-img-top" alt="..." style={item.imgstyle} />
                    </div>
                    <div class="card-footer d-flex align-items-center justify-content-center">
                      {item?.link && <a href={item.link} className="btn btn-primary" target="_blank">En savoir plus</a>}
                      {item?.alert &&
                        <button type="button" className="btn btn-primary" onClick={() => {
                          toast(item?.alert);
                        }}>En savoir plus</button>
                      }
                    </div>
                  </div>
                </div>)
          }

          {
            mylinks
              .filter(
                item => item?.title?.toUpperCase()?.includes(filter?.toUpperCase())
                  || item?.subtitle?.toUpperCase()?.includes(filter?.toUpperCase())
                  || item?.text?.toUpperCase()?.includes(filter?.toUpperCase())
              )
              .map((item, index) =>
                <div key={index} className="col-md-6 col-sm-12">
                  <div className="card" style={{ minHeight: '270px' }}>
                    <div className="card-body">
                      <h5 className="card-title">{item.title}</h5>
                      <h6 className="card-subtitle mb-2 text-body-secondary">{item.subtitle}</h6>
                      <p className="card-text">{item.text}</p>
                    </div>
                    <div class="card-footer d-flex align-items-center justify-content-center">
                      <Link className="btn btn-primary" to={item.to}>En savoir plus</Link>
                    </div>
                  </div>
                </div>)
          }
        </div>

      </div>

      <Footer/>
    </>
  );
}

export default App;
